import { createContext, ReactNode, useContext, useState, useEffect } from 'react'
import { getActivePlan, activePlan, cancelPlan } from '@/utilities/apphubApi'
import { getShopifyAdminAppUrlV2, redirectToUrl } from '@/utilities/misc'
import { Plan } from './types'
import { useAuth } from './AuthContext'
import { PlanIdMapper, PlanType } from '@/domain/type/common'

type PlanProviderProps = {
  children: ReactNode
}

export type PlanContext = {
  currentShopPlan?: Plan
  activeSelectedPlan: (planId: string) => Promise<void>
  cancelCurrentPlan: () => Promise<void>
  isInPlan: (planType: PlanType) => boolean
  isPlanFetched: boolean
}

const PlanContext = createContext({} as PlanContext)

export function usePlan() {
  return useContext(PlanContext)
}

export function PlanProvider({ children }: PlanProviderProps) {
  const { shopifyDomain, getSessionToken, isLoading } = useAuth()
  const testPayment = process.env.NODE_ENV !== 'production'

  const [currentShopPlan, setCurrentShopPlan] = useState<Plan>()
  const [isPlanFetched, setIsPlanFetched] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (isLoading) return

      const shopPlan = await getActivePlan(getSessionToken, shopifyDomain, testPayment)
      setCurrentShopPlan(shopPlan)
      setIsPlanFetched(true)
    })()
  }, [shopifyDomain, isLoading])

  const activeSelectedPlan = async (planId: string) => {
    const returnUrl = `${getShopifyAdminAppUrlV2(shopifyDomain)}/plan?activated=${planId}`
    const confirmationUrl = await activePlan(
      getSessionToken,
      planId,
      returnUrl,
      shopifyDomain,
      testPayment
    )
    redirectToUrl(confirmationUrl)
  }

  const cancelCurrentPlan = async () => {
    if (!currentShopPlan) return

    await cancelPlan(getSessionToken)
    setCurrentShopPlan(undefined)
  }

  const isInPlan = (planType: PlanType) => {
    console.log(currentShopPlan)
    return !!currentShopPlan && PlanIdMapper[currentShopPlan.upatraPlanId] >= planType
  }

  return (
    <PlanContext.Provider
      value={{
        currentShopPlan,
        activeSelectedPlan,
        cancelCurrentPlan,
        isInPlan,
        isPlanFetched,
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}
